export default {
    Home: {
        Home: 'Home',
        Product: 'Product',
        Gateway:'Payment Gateway',
        Transfer:'Transfer Services',
        CONTACT: 'CONTACT SALES',
        Title: "The most professional payment technology company in Indonesia",
        Introduce: 'One Ecosystem for All',
        ContainerFirst1: 'Freedom is about Trust.',
        ContainerFirst2: 'Freedom is Convenience.',
        ContainerFirst3: 'Freedom is expanding Opportunities.',
        ContainerFirst4: 'Do More with ATTAPAY',
        ContainerSecond1: 'Partnership Start With Trust',
        ContainerSecond2: 'Collaboration begins from building a bond of trust between two involving parties so that both will support and go hand in hand towards a common goal. We believe that the success of your business, is also our business. Use a payment solution that is reliable and secure which ensures every transaction you make is successful!',
        Learn:'Learn More',
        ContainerThird1:'Boosting Your Business',
        ContainerThird2:'We support economic growth by pushing the time spent for each transaction through an online payment system which automatically opening new market opportunities. It is time to take control to reach success in your business with a partner who has experience and solution for your business to skyrocket!',
        ContainerFourth1:'Think Beyond Payments',
        ContainerFourth2:'An adaptive transaction solution is a consistent step that we do to increase awareness and understanding of the people of a world that is cashless. Through our Collaborative Commerce, we are in partnership with the communities so that non-cash activities will be more productive and dynamic. This is our way to manifest a Cashless Society that is creative and with the freedom to move independently.',
        Partners:'Extensive Payment Partners',
        Channels:'Browse Through Our List of Payment Channels',
        Together:"Let's Collaborate and Grow Together!",
        Join:'Join Our Payment Ecosystem Today',
        MORE:'READ MORE',
        Company:'COMPANY',
        About:'About Us',
        Contact:'Contact Us'
    },
    Gateway:{
        Payment:'Payment Gateway',
        Pioneers:"Pioneers Don't Rest, We Get Better",
        Features:'Top Features',
        Various:'Various Type of Payment Services',
        VariousTip:'Payment through websites or payment links, invoices or other alternatives. We have a wide range of payment methods to support your transaction in your business.',
        Wide:'Wide Range of Payment Channels',
        WideTip:'We connect businesses to over than 20 banks and non-financial institution in Indonesia to open a wider range of payment options to help you grow your business.',
        Scalable:'Scalable System',
        ScalableTip:'Our technology will support as you grow bigger business.',
        Secure:'Secure and Accurate',
        SecureTip:'With an intelligent and incisive fraud detection system to ensure your transactions are secure and successful.',
        Strong:'Strong Business Analytics',
        StrongTip:'Analyzing your transactional data to help you boost your business growth and provide insights on current business trends.',
        Single:'Single reporting structure',
        SingleTip:'A singular reporting system that covers all of your transaction data.',
        Adaptive:'Adaptive Payment Page',
        AdaptiveTip:'We simplify payments for our merchants by delivering options of payment pages that match their business model.',
        Link:'Link Payment',
        LinkTip:'You can sell almost anything without having to have a website. We offer a payment link to your items which can be easily accessed and shared to social media or messaging applications.',
        Invoice:'E-Invoice',
        InvoiceTip:'For direct billings to your customers. We are ready to help you send electronic invoices through email.',
        Account:'Account Billing',
        AccountTip:"A suitable product for you, who own subscription business so you can conduct auto-debit on customer's credit card based on the predetermined time period.",
        Risk:'Risk Engine',
        RiskTip:'A product that can prevent fraudulent activities in credit card payment by utilizing the latest technology based on credit card transaction data which has been collected for the past 13 years.',
        Deposit:'Deposit Top Up',
        DepositTip:'A close loop product with a concept almost similar to core banking and can go alongside a pre-existing system in your business ecosystem.',
        Mobile:'Mobile Application Integration',
        MobileTip:'We offer native integration service for your mobile app-based business, both for Android and iOS.',
        Commerce:'e-Commerce CMS Integration',
        CommerceTip:'We also offer integration services for your ongoing business on e-commerce platforms such as: Magento, Opencart, WooCommerce, Prestashop, Drupalcommerce, Drupal, Ubercart, Joomla, Shopify, and WHMCS.', 
    },
    Transfer:{
        Transfer:'Transfer Services',
        TransferTip:"One to many transfers are now real-time and secure",
        Features:'Top Features',
        Automatic:'Automatic System',
        AutomaticTip:'With an integrated automatic system, users are in charge of all transaction traffic, therefore, reducing the possibility of loss caused by human error.',
        Real:'Real Time',
        RealTip:'Fund transfer process can be conducted from anywhere to all bank accounts across Indonesia and DOKU’s e-Wallet.',
        Dual:'Dual Access Level',
        DualTip:'The presence of a creator and approver tools inside Merchant Dashboard will increase transaction security. Confirmation will be sent email to both parties',
        Disbursement:'Disbursement',
        DisbursementTip:'One to many money transfer services to businesses that require to make large number of transfers to many beneficiaries. Disburse funds real time from anywhere.',
        Remittance:'Remittance',
        RemittanceTip:"Inbound or outbound fund transfer within countries' active corridor is fast without having to go to a bank. A small amount of money transfer's fee will be deducted from your transaction.",    
    },
    Company:{
        About:'We Are ATTAPAY',
        Slogan:'A Payments Technology Company with Unified Services',
        ContentFirst:'Transaction is the lungs of any breathing economy, that is why our first step starts with a big dream to pave way towards a freedom of transaction. As the pioneer of electronic payment in Indonesia, continuous technological innovations are conducted to generate payment solutions that answer any business needs, along with the dynamics in the economy.',
        ContentSecond:'From paying and getting paid to transferring fund, all are possible with ATTAPAY. For more than one decade, we have grown together alongside with large and medium scaled companies to personal sellers from various lines of businesses, ranging from the transportation, tourism, insurance, retail, donation, communities and many more. Collaboration with partners such as local and international banks, also non-banking institutions has strengthened our reputation as a trusted local electronic payment solution.',
        ContentThird:'Together, we are now more unimpeded in our breath and movement to shatter limitations in transactions, with a diverse range of payment systems, challenging the time constraints using real time transaction system.',
        ContentFourth:'The payment transformation from conventional to digital, frees us from all existing limitations, resulting a faster pathway towards financial literacy in Indonesia.',
        CenterFirstTitle:'Payment Ecosystem',
        CenterFirstTip:"To provide a payment solution and support the overall business growth, these are the three pillars of our payment ecosystem that is supportive and strengthens partner's businesses.",
        CenterSecondTitle:'Our Vision',
        CenterSecondTip:'We are determined to play a role in Indonesia’s digital economic growth. With an undying level of commitment and limitless innovation. We are consistently moving forward to solidify a position as the leading and trusted payment technology company.',
        Contact:'Contact Us',
        Email:'Email',
        Name:'Name',
        Address:'Address',
        CompanyName:'ATTAPAY LIMITED',
        CompanyAddress:'Room 11, 27/F, K. Wah International Centre, 191 Java Road, North Point, Hong Kong'
    }
}
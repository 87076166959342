/**
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */
import {
    isJson
} from '@/utils/validate'
const getLocalStorage = (key) => {
    const value = localStorage.getItem(key)
    if (isJson(value)) {
        return JSON.parse(value)
    } else {
        return false
    }
}
const {
    language
} = getLocalStorage('language')
const state = () => ({
    language: language || 'en',
})
const getters = {
    language: (state) => state.language,
}
const mutations = {
    changeLanguage(state, language) {
        state.language = language
        localStorage.setItem('language', `{"language":"${language}"}`)
    },
}
const actions = {
    changeLanguage: ({
        commit
    }, language) => {
        commit('changeLanguage', language)
    },
}
export default {
    state,
    getters,
    mutations,
    actions
}
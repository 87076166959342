<template>
<div id="app">
    <div class="main-header-overlay">
        <div class="main-container">
            <el-row class="row">
                <el-col :span="6" class="col">
                    <div class="router-test">
                        <router-link :to="{ path: '/' }">
                        <img src="../src/assets/img/logo.png" alt="" class="logo-img" />
                        </router-link>
                    </div>
                </el-col>
                <el-col :span="12" class="col">
                    <ul class="menu-list">
                        <li>
                            <div class="link-content" @click="handleHome">
                                {{ $t("Home.Home") }}
                            </div>
                        </li>
                        <li>
                            <el-popover  trigger="hover" placement="bottom" :visible-arrow="false" popper-class="my-popover">
                                <div class="popover-content">
                                    <div class="popover-content-title" @click="handleRoute('PaymentGateway')">{{$t('Home.Gateway')}}</div>
                                    <div class="popover-content-title" @click="handleRoute('TransferServices')">{{$t('Home.Transfer')}}</div>
                                </div>
                                <div slot="reference" class="link-content">{{ $t("Home.Product") }}</div>
                            </el-popover>
                        </li>
                        <li
                            v-if="language === 'en'"
                            class="language-btn"
                            @click="handleCommand('id')"
                        >
                            <div class="link-content">
                            <span class="language">ID</span>
                            </div>
                        </li>
                        <li
                            v-if="language === 'id'"
                            class="language-btn"
                            @click="handleCommand('en')"
                        >
                            <div class="link-content">
                            <span class="language">EN</span>
                            </div>
                        </li>
                    </ul>
                </el-col>
                <div class="btn-contact">
                    <div class="btn-white" @click="handleRoute('Contact')">
                        <span class="btn-sm">
                            <span>
                            <i class="iconfont icon-oomessage"></i>
                            {{ $t("Home.CONTACT") }}
                            </span>
                        </span>
                    </div>
                </div>
            </el-row>
        </div>
    </div>
    <router-view />
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
        language: "setting/language",
        }),
    },
    // 全局监听，写在App.vue里面
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        ...mapActions({
            changeLanguage: "setting/changeLanguage",
        }),
        handleCommand(command) {
            this.changeLanguage(command);
            this.$i18n.locale = command;
        },
        handleRoute(path) {
            this.$router.push({ name:path });
        },
        handleScroll() {
            // 监听事件
            window.addEventListener("scroll", function () {
                let t = $("body, html").scrollTop(); // 目前监听的是整个body的滚动条距离
                if (t > 1000) {
                $(".main-header-overlay").addClass("box-active");
                $(".link-content").addClass("link-content-active");
                $(".language").addClass("language-active");
                $(".btn-white").addClass("btn-white-active");
                } else {
                $(".main-header-overlay").removeClass("box-active");
                $(".link-content").removeClass("link-content-active");
                $(".language").removeClass("language-active");
                $(".btn-white").removeClass("btn-white-active");
                }
            });
        },
        handleHome() {
            this.$router.push({ name: "Home" });
        },
    },
};
</script>

<style>
.el-popover.my-popover{
    padding:0;
}
</style>
<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.main-header-overlay {
    width: 100%;
    height: 115px;
    position: relative;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    background-color: transparent;
}
.box-active {
    position: fixed;
    height: 91px;
    line-height: 91px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 7%);
    top: 0;
    background-color: #fff;
}
.main-container {
    width: 1170px;
    max-width: 1197px;
    height: 100%;
    display: flex;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
}
.row {
    width: 100%;
    display: flex;
    min-height: 1px;
    align-items: center;
}
.col {
    height: 115px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
}
.router-test {
    text-align: left;
    padding: 28px 0;
}
.logo-img {
    width: 55px;
    height: 55px;
}
.menu-list {
    display: flex;
    list-style: none;
    height: 100%;
    line-height: 100%;
    margin: 0;
}
.link-content {
    height: 95px;
    text-decoration: none;
    color: #fff;
    line-height: 95px;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
}
.link-content-active {
    height: 91px;
    text-decoration: none;
    color: #424242;
    line-height: 91px;
    padding: 10px 15px;
    font-size: 15px;
}
a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.language-btn {
    margin-left: 200px;
}
.language {
    padding: 8px 8px;
    border: 1px solid #fff;
    cursor: pointer;
}
.language-active {
    padding: 8px 8px;
    border: 1px solid #eeeff2;
    background-color: #f9f9f9;
}
.btn-white {
    background-color: #fff;
    color: #424242;
    padding: 0 8px;
    font-size: 15px;
    font-weight: 700;
    border-color: #f7f7f7;
    border-radius: 4px;
    height: 48px;
    line-height: 48px;
    margin-left: 25px;
    position: relative;
    transition: all 0.5s linear;
    cursor: pointer;
}
.btn-white-active {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
}
.btn-white:hover {
    top: -2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 7%);
}
.btn-sm {
    padding: 10px 18px;
}
.el-popover{
    background-color: yellow;
    padding: 0 !important;
}
.popover-content{
    font-size: 16px;
    text-align: center;
    color: #909399;
}
.popover-content-title{
    padding: 16px 20px;
    cursor: pointer;
}
.popover-content-title:hover{
    color: #606266;
    background: #ececec;

}
</style>

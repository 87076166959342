import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import idLocale from 'element-ui/lib/locale/lang/id'
import en from './en'
import id from './id'
Vue.use(VueI18n)

const messages = {
    en: {
        ...en,
        ...enLocale
    },
    id: {
        ...id,
        ...idLocale
    },
}
function getLanguage() {
    return store.getters['setting/language'] || 'en'
}
const i18n = new VueI18n({
    locale:  getLanguage(), // set locale
    messages // set locale messages
})

// 导出messages 给切换语言的时候用
export default i18n
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component:  () => import(/* webpackChunkName: "about" */ '../views/home')
  },
  {
    path: '/gateway',
    name: 'PaymentGateway',
    component:  () => import(/* webpackChunkName: "about" */ '../views/product/gateway.vue')
  },
  {
    path: '/transfer',
    name: 'TransferServices',
    component:  () => import(/* webpackChunkName: "about" */ '../views/product/transfer.vue')
  },
  {
    path: '/about',
    name: 'About',
    component:  () => import(/* webpackChunkName: "about" */ '../views/company/about.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component:  () => import(/* webpackChunkName: "about" */ '../views/company/contact.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

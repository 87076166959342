export default {
    Home:{
        Home:'Rumah',
        Product:'Produk',
        Gateway:'Payment Gateway',
        Transfer:'Transfer Services',
        CONTACT:'HUBUNGI PENJUALAN',
        Title:'Perusahaan teknologi pembayaran paling profesional di Indonesia',
        Introduce:'Satu Ekosistem untuk Semua',
        ContainerFirst1: 'Kebebasan adalah tentang Kepercayaan.',
        ContainerFirst2: 'Kebebasan adalah Kenyamanan.',
        ContainerFirst3: 'Kebebasan memperluas Peluang.',
        ContainerFirst4: 'Lakukan Lebih Banyak dengan ATTAPAY',
        ContainerSecond1: 'Kemitraan Bermula dari Sebuah Kepercayaan',
        ContainerSecond2: 'Kerja sama dimulai dari rasa percaya, agar setiap pihak bergerak saling mendukung dan berjalan beriringan untuk mencapai tujuan. Kami percaya bahwa keberhasilan bisnis Anda adalah keberhasilan kami juga. Gunakan solusi yang dapat diandalkan guna menjamin keamanan sistem pembayaran dan kesuksesan di setiap transaksi.',
        Learn:'Pelajari lebih lanjut ',
        ContainerThird1:'Tingkatkan Bisnis Anda',
        ContainerThird2:'Kami mendukung pertumbuhan ekonomi dengan mendorong percepatan transaksi melalui sistem pembayaran digital, yang secara otomatis membuka peluang pasar baru. Saatnya ambil kendali untuk meraih kesuksesan bisnis dengan mitra penyedia solusi pembayaran berpengalaman yang memperkuat laju bisnis Anda.',
        ContainerFourth1:'Lebih dari Sebuah Pembayaran',
        ContainerFourth2:'Kami secara konsisten melakukan inovasi solusi pembayaran yang mampu beradaptasi untuk meningkatkan pemahaman masyarakat akan dunia non-tunai. Melalui Collaborative Commerce (C-Commerce), kami bekerjasama dengan komunitas sehingga aktivitas non-tunai semakin produktif dan dinamis. Inilah dukungan kami untuk mewujudkan Cashless Society yang kreatif dan bebas bergerak.',
        Partners:'Mitra Pembayaran Lengkap',
        Channels:'Lihat Metode Pembayaran',
        Together:"Mari berkolaborasi dan tumbuh bersama",
        Join:'Bergabunglah dengan ekosistem pembayaran kami, hari ini.',
        MORE:'SELENGKAPNYA',
        Company:'COMPANY',
        About:'Tentang',
        Contact:'Contact Us'
    },
    Gateway:{
        Payment:'Payment Gateway',
        Pioneers:"Perintis Terus Berinovasi, Memperkuat Diri",
        Features:'Fitur utama',
        Various:'Layanan Pembayaran Bervariasi',
        VariousTip:'Terima pembayaran melalui website atau link pembayaran, invoice atau alternatif lainnya. Kami menyediakan pilihan metode pembayaran yang luas untuk mendukung transaksi bisnis para mitra usaha.',
        Wide:'Metode Pembayaran Beragam',
        WideTip:'Kami menghubungkan bisnis Anda ke lebih dari 20 bank dan lembaga non-keuangan di Indonesia untuk memberi Anda keleluasaan dalam hal pembayaran, sehingga bisnis Anda pun bisa lebih berkembang.',
        Scalable:'Sistem yang Tumbuh Bersama',
        ScalableTip:'Kami memiliki teknologi yang siap tumbuh bersama dengan bisnis Anda.',
        Secure:'Aman dan Akurat',
        SecureTip:'Transaksi yang Anda lakukan akan berjalan dengan aman dan lancar berkat sistem pintar yang mampu mendeteksi potensi kecurangan.',
        Strong:'Analisis Bisnis yang Kuat',
        StrongTip:'Kami dapat menganalisa data transaksi Anda guna mendorong pertumbuhan bisnis dan memberikan pandangan terkait tren bisnis terbaru.',
        Single:'Struktur Pelaporan Tunggal',
        SingleTip:'Sistem pelaporan tunggal yang merangkum semua transaksi data Anda.',
        Adaptive:'Halaman Pembayaran yang Adaptif',
        AdaptiveTip:'Kami menyediakan payment page serta pilihan metode pembayaran sesuai kebutuhan bisnis Anda.',
        Link:'Link Pembayaran',
        LinkTip:'Anda dapat menjual hampir apa saja tanpa harus memiliki situs web. Kami menawarkan link pembayaran yang mengacu pada produk yang Anda jual. Link ini bisa dengan mudah diakses dan dibagikan lewat media sosial atau aplikasi chat.',
        Invoice:'E-Invoice',
        InvoiceTip:'Produk ini dapat digunakan untuk penagihan langsung ke pelanggan Anda. Kami siap mengirimkan invoice elektronik melalui email sesuai dengan rincian transaksi pelanggan.',
        Account:'Account Billing',
        AccountTip:"Produk yang cocok bagi Anda yang memiliki bisnis langganan sehingga Anda dapat melakukan auto-debit pada kartu kredit pelanggan berdasarkan periode waktu yang ditentukan.",
        Risk:'Risk Engine',
        RiskTip:'Produk yang dapat mencegah terjadinya fraud dalam pembayaran kartu kredit dengan menggunakan teknologi terkini berdasarkan data-data transaksi kartu kredit yang sudah terkumpul sejak 13 tahun yang lalu.',
        Deposit:'Deposit Top Up',
        DepositTip:'Produk yang memiliki konsep hampir serupa dengan core banking ini bersifat close loop dan dapat berjalan berdampingan dengan sistem yang sudah ada dalam ekosistem bisnis Anda.',
        Mobile:'Integrasi Aplikasi Mobile',
        MobileTip:'Kami menyediakan layanan integrasi secara native untuk bisnis Anda yang berbasis aplikasi mobile, baik Android maupun iOS.',
        Commerce:'Integrasi e-commerce CMS',
        CommerceTip:'Kami juga menyediakan layanan integrasi untuk bisnis Anda yang telah berjalan di atas platform e-commerce, seperti: Magento, Opencart, WooCommerce, Prestashop, Drupalcommerce, Drupal, Ubercart, Joomla, Shopify, dan WHMCS.', 
    },
    Transfer:{
        Transfer:'Transfer Services',
        TransferTip:"Transfer ke lebih dari satu rekening (one to many transfers) bisa dilakukan secara real-time dan aman.",
        Features:'Fitur utama',
        Automatic:'Automatic System',
        AutomaticTip:'Dengan sistem otomatis yang terintegrasi, pengguna memegang penuh semua arus transaksi, sehingga meminimalkan resiko kelalaian.',
        Real:'Real Time',
        RealTip:'Transfer dana dapat dilakukan dari mana saja ke semua rekening bank di seluruh Indonesia dan DOKU e-Wallet.',
        Dual:'Sistem yang Tumbuh Bersama',
        DualTip:'Adanya fungsi creator dan approver pada Dasbor Merchant membuat keamanan transfer lebih terjamin. Fitur ini akan mengirimkan email konfirmasi kepada dua belah pihak yang bertransaksi.',
        Disbursement:'Disbursement',
        DisbursementTip:'Layanan ini bisa digunakan bagi bisnis yang ingin melakukan transfer uang dalam jumlah besar ke banyak rekening penerima. Dana yang ditransfer dapat dicairkan secara real-time dari mana saja.',
        Remittance:'Remittance',
        RemittanceTip:'Anda dapat melakukan transfer cepat dari dalam maupun luar negeri ke rekening tujuan tanpa harus pergi ke bank. Biaya yang dibutuhkan untuk transfer akan dikurangkan dari transaksi Anda.',
    },
    Company:{
        About:'Tentang Kami',
        Slogan:'Perusahaan penyelenggara sistem pembayaran berbasis teknologi dengan layanan terpadu.',
        ContentFrist:'Transaksi adalah nafas dari perekonomian. Karenanya langkah awal kami dimulai dari sebuah mimpi besar untuk merintis jalan menuju kebebasan bertransaksi. Sebagai pelopor pembayaran elektronik di Indonesia, inovasi teknologi terus dilakukan untuk menghasilkan solusi pembayaran yang bisa menjawab berbagai kebutuhan bisnis, seiring dengan dinamika ekonomi.',
        ContentSecond:'Semua mungkin dilakukan bersama ATTAPAY. Mulai dari soal membayar dan menerima pembayaran, hingga transfer dana. Selama lebih dari satu dekade, kami telah bertumbuh bersama dengan perusahaan besar menengah maupun bisnis individu dari berbagai latar belakang. Mulai dari bisnis transportasi, pariwisata, asuransi, retail, donasi, komunitas, peer to peer lending, dan masih banyak lagi. Kolaborasi dengan mitra seperti bank lokal dan internasional, termasuk institusi non-perbankan, telah memperkuat reputasi kami sebagai solusi pembayaran elektronik lokal yang tepercaya. Bersama kami, ekonomi digital di Indonesia akan lebih berenergi.',
        ContentThird:'Kini kita makin leluasa untuk bernafas dan bergerak menembus batasan dalam bertransaksi, beragam pilihan sistem pembayaran, hingga menantang waktu dengan sistem transaksi yang real-time.',
        ContentFourth:'Transformasi pembayaran, dari konvensional dan menjadi digital, membebaskan kita dari banyak kendala sehingga menghasilkan akses yang lebih cepat menuju literasi keuangan di Indonesia.',
        CenterFirstTitle:'Ekosistem Pembayaran',
        CenterFirstTip:'Menyediakan solusi pembayaran dan mendukung pertumbuhan bisnis secara keseluruhan, inilah tiga pilar ekosistem pembayaran kami yang mampu memperkuat dan mendukung bisnis para mitra.',
        CenterSecondTitle:'Visi kami',
        CenterSecondTip:'Kami bertekad untuk berperan dalam pertumbuhan ekonomi digital di Indonesia. Dengan komitmen tinggi dan inovasi tanpa batas. Kami secara konsisten bergerak maju untuk memperkuat posisi sebagai perusahaan teknologi pembayaran terkemuka dan tepercaya.',
        Contact:'Hubungi',
        Email:'Email',
        Name:'Nama',
        Address:'Alamat',
        CompanyName:'ATTAPAY LIMITED',
        CompanyAddress:'Kamar 11, 27/F, K. Wah International Centre, 191 Java Road, North Point, Hong Kong',
    }
}